export default function initCtaModal() {
    const cta = document.querySelectorAll(".cta");
    
    cta.forEach((item) => {
        const tabs = item.querySelector('.cta__modal-tabs').querySelectorAll('button');
        const callback = item.querySelector('.cta__modal-callback');
        const email = item.querySelector('.cta__modal-email');
        const popuplink = item.querySelector('.cta__popup-link');
        const modal = item.querySelector('.cta__modal');
        const overlay = item.querySelector('.cta__overlay');
        const cross = item.querySelector('.cta__modal-cross');

        popuplink.addEventListener('click', function () {
            modal.classList.add('active');
            overlay.classList.add('active');
        })

        overlay.addEventListener('click', function () {
            modal.classList.remove('active');
            overlay.classList.remove('active');
        })

        cross.addEventListener('click', function () {
            modal.classList.remove('active');
            overlay.classList.remove('active');
        })

        tabs[0].addEventListener("click", function () {
            tabs[1].classList.remove('active');
            tabs[0].classList.add('active');
            callback.classList.add('active');
            email.classList.remove('active');
        });
        tabs[1].addEventListener("click", function () {
            tabs[0].classList.remove('active');
            tabs[1].classList.add('active');
            callback.classList.remove('active');
            email.classList.add('active');
        });
    });
  }
  