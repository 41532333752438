import initPreloader from './modules/preolader';
import initNavigation from './modules/navigation';
import initAccordion from './modules/accordion';
import initSwiper from './modules/swiper';
import initNewProductSwiper from './modules/new-product-swiper';
import initPopularProductSwiper from './modules/popular-product-swiper';
import initCtaModal from './modules/cta-modal';
import initCtaForm from './modules/cta-form';
import initCalculatorSwiper from './modules/calculator-swiper';

import '../scss/styles.scss';

document.addEventListener('DOMContentLoaded', () => {

    jQuery( function( $ ) {
        var debounceTimer;
        $( 'body' ).on( 'change', '.qty', function() {
            clearTimeout(debounceTimer);
            debounceTimer = setTimeout(function() {
                $( '[name="update_cart"]' ).trigger( 'click' );
            }, 800); // Затримка 800 мс перед оновленням кошика
        });
    
        $(document).on( 'click', 'button.plus, button.minus', function() {
            var qty = $( this ).parent( '.quantity' ).find( '.qty' );
            var val = parseFloat(qty.val());
            var max = parseFloat(qty.attr( 'max' ));
            var min = parseFloat(qty.attr( 'min' ));
            var step = parseFloat(qty.attr( 'step' ));
    
            if ( $( this ).is( '.plus' ) ) {
                if ( max && ( max <= val ) ) {
                    qty.val( max );
                } else {
                    qty.val( val + step );
                }
            } else {
                if ( min && ( min >= val ) ) {
                    qty.val( min );
                } else if ( val > 1 ) {
                    qty.val( val - step );
                }
            }
            qty.change(); // Тригер зміни відбудеться з затримкою
        });
    });

initPreloader();
initAccordion();
initSwiper();
initNewProductSwiper();
initPopularProductSwiper();
initCtaModal();
initCtaForm();
initNavigation();
initCalculatorSwiper();

});
