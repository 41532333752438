import Swiper from 'swiper';
import { Navigation } from 'swiper';

export default function initSwiper() {
    const communitySwiper = document.querySelectorAll('.testimonials');
    if (!communitySwiper) return;

    communitySwiper.forEach((el) => {
      const slider = el.querySelector('.testimonials__swiper');

      var swiper = new Swiper(slider, {
        modules: [Navigation],
        slidesPerView: 3,
        centeredSlides: true,
        loop: true,
        breakpoints: {
          0: {
              slidesPerView: 1.16,
              spaceBetween: 16,
          },
          480: {
              slidesPerView: 2,
              spaceBetween: 24,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 24,
          }
      },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        }
      });
  });
}