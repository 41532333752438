export default function initCtaForm() {
    const cta = document.querySelectorAll(".cta");
    const ctaSingle = document.querySelector('.cta-single');

    if (cta && cta.length) {
        cta.forEach((item) => {
            formInitHandler(item);
        });
    } else if (ctaSingle) {
        formInitHandler(ctaSingle);
    }

    function formInitHandler(item) {
        const inputs = item.querySelectorAll('input.wpcf7-form-control');
        const radios = item.querySelectorAll('.wpcf7-list-item input');
        const textarea = item.querySelector('.wpcf7-form-control.wpcf7-textarea');
        const submit = item.querySelector('.wpcf7-form-control.wpcf7-submit');

        let valuObj = {
            tel: false,
            time: false,
            email: false,
            textarea: false,
        }

        function checkValue() {
            Object.values(valuObj).every(value => value === true) ? submit.classList.add('active') : submit.classList.remove('active');
        }

        inputs.forEach(input => {
            input.addEventListener('input', function () {
                valuObj.tel = (input.value && input.hasAttribute('type', 'tel')) ? true : false;
                valuObj.email = (input.value && input.hasAttribute('type', 'email')) ? true : false;
                checkValue();
            })
        })

        radios.forEach(radio => {
            radio.addEventListener('click', function () {
                valuObj.time = true;
                checkValue();
            })
        })

        textarea.addEventListener('input', function () {
            valuObj.textarea = textarea.value ? true : false;
            checkValue();
        })
    }
}
  