// calculator-swiper.js

import Swiper from 'swiper';
import {Navigation} from 'swiper';

export default function initCalculatorSwiper() {
    const communitySwiper = document.querySelectorAll('.calculator');
    if (!communitySwiper) return;

    communitySwiper.forEach((el) => {
        const slider = el.querySelector('.calculator__swiper');

        var swiper = new Swiper(slider, {
            modules: [Navigation],
            slidesPerView: 1,
            loop: true,
            navigation: {
                nextEl: el.querySelector('.calculator__slider-next'),
                prevEl: el.querySelector('.calculator__slider-prev'),
            }
        });
    });

    if (document.querySelector('.tmcp-upload')) {
        document.querySelector('.tmcp-upload').addEventListener('change', function (e) {
            var fileName = e.target.files[0].name;
            if (document.querySelector('.upload-file-text')) document.querySelector('.upload-file-text').textContent = fileName;
        });
    }
}