export default function initAccordion() {
    const acc = document.querySelectorAll(".faq__item");
    
    acc.forEach((accItem) => {
        accItem.addEventListener("click", function () {
            const isActive = this.classList.contains("active");
            
            acc.forEach((item) => {
                item.classList.remove("active");
                const panel = item.querySelector('.faq__item-panel');
                panel.style.maxHeight = null;
                panel.classList.remove("active");
                const head = item.querySelector('.faq__item-accordion');
                head.classList.remove("active");
            });

            if (!isActive) {
                this.classList.add("active");
                const head = this.querySelector('.faq__item-accordion');
                const panel = this.querySelector('.faq__item-panel');
                panel.classList.add("active");
                head.classList.add("active");
                panel.style.maxHeight = panel.scrollHeight + 25 + "px";
            }
        });
    });
}
  