import Swiper from 'swiper';

export default function initNewProductSwiper() {
    const communitySwiper = document.querySelectorAll('.new-products');
    if (!communitySwiper) return;

    communitySwiper.forEach((el) => {
      const slider = el.querySelector('.new-products__swiper');

      var swiper = new Swiper( slider , {
        slidesPerView: 3,
        breakpoints: {
            0: {
                slidesPerView: 1.16,
                spaceBetween: 16,
            },
            480: {
                slidesPerView: 2,
                spaceBetween: 16,
            },
            768: {
                slidesPerView: 3,
                spaceBetween: 24,
            }
        }
      });
    });
}